import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { PlusCircle, Eye, MousePointer, Users, LayoutGrid, LayoutList } from 'lucide-react';

import './promoteContainer.css';

const styles = {
  promoteWrapper: {
    color: '#ffffff',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    minHeight: '94vh',
  },
  linkWrapper: {
    color: '#ffffff',
    textDecoration: 'none',
  },
  promoteTitleContainer: {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  promoteTitle: {
    margin: 0,
    paddingLeft: '12px',
  },
  createPromoWrapper: {
    background: 'linear-gradient(17deg, rgba(60, 0, 255, 0.85) 0%, rgb(134, 1, 255) 26%, rgb(255, 10, 103) 86%)',
    borderRadius: '5px',
    fontSize: '17px',
  },
  createPromoLink: {
    display: 'flex',
    gap: '8px',
    padding: '10px',
  }
};

const PromoteContainer = () => {
  const [promos, setPromos] = useState([]);
  const [viewMode, setViewMode] = useState("grid")

  useEffect(() => {
    const items = [];
  
    for (let i = 0; i < sessionStorage.length; i++) {
      const key = sessionStorage.key(i);
      if (key.startsWith('promo')) {
        try {
          const value = JSON.parse(sessionStorage.getItem(key));
          // optional: validate value shape if needed
          if (value && typeof value === 'object') {
            items.push(value);
          }
        } catch (err) {
          console.error(`Error parsing item for key ${key}`, err);
        }
      }
    }
  
    setPromos(items);
  }, []);

  const togglePromoStatus = (id) => {
    setPromos(
      promos.map((promo) => {
        if (promo.id === id) {
          return { ...promo, active: !promo.active }
        }
        return promo
      }),
    )
  }

  return (
    <div style={styles.promoteWrapper}>
      <Row nogutters="true" className="promote-raw-wrapper">
        <Col sm={12} className='promote-title-container' style={styles.promoteTitleContainer}>
          <h3 style={styles.promoteTitle}>Promo Manager</h3>

          <div className="promote-header-actions">
            <div className="promote-view-toggle">
              <button
                className={`promote-view-toggle-btn ${viewMode === "grid" ? "active" : ""}`}
                onClick={() => setViewMode("grid")}
              >
                <LayoutGrid size={18} />
              </button>
              <button
                className={`promote-view-toggle-btn ${viewMode === "list" ? "active" : ""}`}
                onClick={() => setViewMode("list")}
              >
                <LayoutList size={18} />
              </button>
            </div>

            <Link to="createpromo" className="promote-create-promo-btn">
              <PlusCircle size={20} />
              Create Promo
            </Link>
          </div>
        </Col>
        <div className={`promote-promo-grid ${viewMode === "list" ? "promote-list-view" : ""}`}>
          {promos.map((promo) => (
            <div key={promo.id} className={`promote-promo-card ${promo.gradient}`}>
              <div className="promote-promo-header">
                <div className="promote-promo-type">Promo Type: {promo.type}</div>
                <div className="promote-promo-status">
                  <span>{promo.active ? "Active" : "Inactive"}</span>
                  <div
                    className={`promote-status-toggle ${promo.active ? "active" : ""}`}
                    onClick={() => togglePromoStatus(promo.id)}
                  >
                    <div className="promote-toggle-handle"></div>
                  </div>
                </div>
              </div>

              <div className="promote-promo-stats">
                <div className="promote-stat-item">
                  <Eye className="promote-stat-icon" />
                  <span className="promote-stat-value">{promo.stats.views}</span>
                  <span className="promote-stat-label">Views</span>
                </div>
                <div className="promote-stat-item">
                  <MousePointer className="promote-stat-icon" />
                  <span className="promote-stat-value">{promo.stats.clicks}</span>
                  <span className="promote-stat-label">Clicks</span>
                </div>
                <div className="promote-stat-item">
                  <Users className="promote-stat-icon" />
                  <span className="promote-stat-value">{promo.stats.fans}</span>
                  <span className="promote-stat-label">Fans</span>
                </div>
              </div>

              <div className="promote-promo-footer">
                <div className="promote-promo-date">Created on {promo.createdAt}</div>
              </div>
            </div>
          ))}
        </div>
      </Row>
    </div>
  );
};

export default PromoteContainer;
