import { useState, useRef, useEffect } from "react"
import {
  Plus,
  BarChart,
  GripVertical,
  Edit2,
  Trash2,
  Pencil,
  ChevronDown,
  X,
  Upload,
  Link,
  Eye,
  MousePointer,
  Users,
  CheckCircle,
} from "lucide-react"
import { Button, Card, Avatar } from "antd"
import { ScrollArea } from "@radix-ui/react-scroll-area"
import { Separator } from "@radix-ui/react-separator"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import { v4 as uuidv4 } from 'uuid';
import { withRouter } from 'react-router-dom';

import AddLinkModal from "../components/modals/AddLinkModal/AddLinkModal"
import AddTextModal from "../components/modals/AddTextModal/AddTextModal"
import AddContactFormModal from "../components/modals/AddContactFormModal/AddContactFormModal"
import AddEventModal from "../components/modals/AddEventModal/AddEventModal"
import "./templateBuilder.css"
import AnalyticsDialog from "../components/modals/AnalyticsModal/AnalyticsModal"
import { AddSocialModal } from "../components/modals/AddSocialModal/AddSocialModal"
import { SocialIcon } from "../components/modals/AddSocialModal/SocialIcon"
import DeleteBlockModal from "../components/modals/DeleteBlockModal/DeleteBlockModal"
import { MobileContactForm } from "../components/modals/MobileContactForm/MobileContactForm"

// Theme definitions
const themes = [
  { id: "cyberpunk", name: "Cyberpunk", color: "#9933ff" },
  { id: "neon-city", name: "Neon City", color: "#00ff88" },
  { id: "sunset-beach", name: "Sunset Beach", color: "#ff6b35" },
  { id: "tropical-paradise", name: "Tropical Paradise", color: "#00d4bb" },
  { id: "midnight-aurora", name: "Midnight Aurora", color: "#7b61ff" },
  { id: "digital-wave", name: "Digital Wave", color: "#40a9ff" },
  { id: "geometric-pulse", name: "Geometric Pulse", color: "#ff5370" },
  { id: "liquid-gradient", name: "Liquid Gradient", color: "#ff69b4" },
  { id: "neon-particles", name: "Neon Particles", color: "#e100ff" },
  { id: "cosmic-blend", name: "Cosmic Blend", color: "#cf23cf" },
]

// Theme Selector Component
const ThemeSelector = ({ selectedTheme, onChange }) => {
  const [isOpen, setIsOpen] = useState(false)
  const currentTheme = themes.find((t) => t.id === selectedTheme) || themes[0]

  return (
    <div className="theme-dropdown">
      <button
        className="analytics-button"
        onClick={() => setIsOpen(!isOpen)}
        style={{
          borderColor: currentTheme.color,
          color: currentTheme.color,
        }}
      >
        <div
          style={{
            width: "14px",
            height: "14px",
            borderRadius: "50%",
            backgroundColor: currentTheme.color,
            marginRight: "8px",
          }}
        />
        {currentTheme.name}
        <ChevronDown size={16} style={{ marginLeft: "6px" }} />
      </button>

      {isOpen && (
        <div
          style={{
            position: "absolute",
            top: "100%",
            right: 0,
            marginTop: "8px",
            width: "200px",
            backgroundColor: "var(--card-background)",
            border: "1px solid var(--border-color)",
            borderRadius: "8px",
            zIndex: 100,
            boxShadow: "0 8px 20px rgba(0, 0, 0, 0.4)",
          }}
        >
          {themes.map((theme) => (
            <div
              key={theme.id}
              style={{
                display: "flex",
                alignItems: "center",
                padding: "10px 16px",
                cursor: "pointer",
                backgroundColor: theme.id === selectedTheme ? "rgba(255, 255, 255, 0.05)" : "transparent",
                color: theme.id === selectedTheme ? theme.color : "var(--text-primary)",
              }}
              onClick={() => {
                onChange(theme.id)
                setIsOpen(false)
              }}
            >
              <div style={{ display: "flex", alignItems: "center", flex: 1 }}>
                <div
                  style={{
                    width: "14px",
                    height: "14px",
                    borderRadius: "50%",
                    backgroundColor: theme.color,
                    marginRight: "10px",
                  }}
                />
                {theme.name}
              </div>
              {theme.id === selectedTheme && <Check size={16} />}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

const TemplateBuilder = ({ user, history }) => {
  const [showAddLinkModal, setShowAddLinkModal] = useState(false)
  const [showAddTextModal, setShowAddTextModal] = useState(false)
  const [showAddContactFormModal, setShowAddContactFormModal] = useState(false)
  const [showAddEventModal, setShowAddEventModal] = useState(false)
  const [isAnalyticsOpen, setIsAnalyticsOpen] = useState(false)
  const [blocks, setBlocks] = useState([])
  const [editingBlock, setEditingBlock] = useState(null)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [blockToDelete, setBlockToDelete] = useState(null)
  const [showBioModal, setShowBioModal] = useState(false)
  const [bio, setBio] = useState(user.bio || "")
  const [name, setName] = useState(user.firstName || "")
  const [profileImage, setProfileImage] = useState(user.profileImage || "")
  const [currentTheme, setCurrentTheme] = useState("cyberpunk")
  const [customerUrl, setCustomerUrl] = useState("")
  const [showPublishSuccess, setShowPublishSuccess] = useState(false)

  const [showAddSocialModal, setShowAddSocialModal] = useState(false)
  const [socialLinks, setSocialLinks] = useState({})

  const moduleTypes = ["link", "text", "contact", "event"];

  const addBlock = (type, content) => {
    setBlocks((prevBlocks) => [
      ...prevBlocks,
      {
        id: `${type}-${Date.now()}`,
        type,
        content,
      },
    ])
  }

  const updateBlock = (id, content) => {
    setBlocks((prevBlocks) => prevBlocks.map((block) => (block.id === id ? { ...block, content } : block)))
    setEditingBlock(null)
  }

  const deleteBlock = (id) => {
    setBlocks((prevBlocks) => prevBlocks.filter((block) => block.id !== id))
    setShowDeleteModal(false)
    setBlockToDelete(null)
  }

  const handleEdit = (block) => {
    setEditingBlock(block)
    switch (block.type) {
      case "link":
        setShowAddLinkModal(true)
        break
      case "text":
        setShowAddTextModal(true)
        break
      case "contact":
        setShowAddContactFormModal(true)
        break
      case "event":
        setShowAddEventModal(true)
        break
    }
  }

  const handleDelete = (block) => {
    setBlockToDelete(block)
    setShowDeleteModal(true)
  }

  const handleSaveLink = (link) => {
    if (editingBlock) {
      updateBlock(editingBlock.id, link)
    } else {
      addBlock("link", link)
    }
    setShowAddLinkModal(false)
  }

  const handleSaveText = (text) => {
    if (editingBlock) {
      updateBlock(editingBlock.id, { content: text })
    } else {
      addBlock("text", { content: text })
    }
    setShowAddTextModal(false)
  }

  const handleSaveEvent = (event) => {
    if (editingBlock) {
      updateBlock(editingBlock.id, event)
    } else {
      addBlock("event", event)
    }
    setShowAddEventModal(false)
  }

  const handleSaveContactForm = (contactForm) => {
    if (editingBlock) {
      updateBlock(editingBlock.id, contactForm)
    } else {
      addBlock("contact", contactForm)
    }
    setShowAddContactFormModal(false)
  }

  const handleCloseModal = () => {
    setEditingBlock(null)
    setShowAddLinkModal(false)
    setShowAddTextModal(false)
    setShowAddContactFormModal(false)
    setShowAddEventModal(false)
  }

  const openModal = (type) => {
    switch (type) {
      case "link":
        setShowAddLinkModal(true)
        break
      case "text":
        setShowAddTextModal(true)
        break
      case "contact":
        setShowAddContactFormModal(true)
        break
      case "event":
        setShowAddEventModal(true)
        break
    }
  }

  const onDragEnd = (result) => {
    if (!result.destination) {
      return
    }

    const items = Array.from(blocks)
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)

    setBlocks(items)
  }

  const handleSaveProfile = (newData) => {
    setBio(newData.bio)
    setName(newData.name)
    setProfileImage(newData.profileImage)
    setShowBioModal(false)
  }

  const handleSaveSocialLink = ({ platform, username }) => {
    setSocialLinks((prev) => ({
      ...prev,
      [platform]: { username },
    }))
  }

  const handleEditSocialLink = (platform, newUsername) => {
    setSocialLinks((prev) => ({
      ...prev,
      [platform]: { username: newUsername },
    }))
  }

  const handleDeleteSocialLink = (platform) => {
    setSocialLinks((prev) => {
      const newLinks = { ...prev }
      delete newLinks[platform]
      return newLinks
    })
  }

  const handlePublish = () => {
    const newPromo = {
      id: `promo-${blocks.length + 1}`,
      type: "Link Page",
      active: true,
      stats: {
        views: Math.floor(Math.random() * 2000),
        clicks: Math.floor(Math.random() * 1000),
        fans: Math.floor(Math.random() * 200),
      },
      createdAt: new Date().toLocaleDateString("en-US", { month: "short", day: "numeric", year: "numeric" }),
      gradient: "purple-blue",
    }
    sessionStorage.setItem(`promo-${uuidv4()}`, JSON.stringify(newPromo));
    setShowPublishSuccess(true)
  }

  const handleGoToPromoManager = () => {
    setShowPublishSuccess(false)
    history.push("/promote");
  }

  const displayBlocksReverse = [...blocks].reverse()

  return (
    <div className="page-wrapper">
      <div className="builder-header">
        <h1 className="title1">Link Builder</h1>
        <div className="header-actions">
          <ThemeSelector selectedTheme={currentTheme} onChange={setCurrentTheme} />
          <button className="analytics-button" onClick={() => setIsAnalyticsOpen(true)}>
            <BarChart size={20} />
            Analytics
          </button>
        </div>
      </div>
      {/* KNKT Promo URL Field */}
      <div className="customer-url-container">
        <div className="customer-url-label">
          <Link size={16} />
          <span>Customer URL</span>
        </div>
        <input
          type="text"
          className="customer-url-input"
          placeholder="https://example.com"
          value={customerUrl}
          onChange={(e) => setCustomerUrl(e.target.value)}
        />
      </div>
      <div className="template-builder">
        <div className="builder-left">
          <AddLinkModal
            isOpen={showAddLinkModal}
            onSave={handleSaveLink}
            onClose={handleCloseModal}
            initialData={editingBlock?.type === "link" ? editingBlock.content : null}
          />

          <AddTextModal
            isOpen={showAddTextModal}
            onSave={handleSaveText}
            onClose={handleCloseModal}
            initialData={editingBlock?.type === "text" ? editingBlock.content.content : null}
          />

          <AddContactFormModal
            isOpen={showAddContactFormModal}
            onSave={handleSaveContactForm}
            onClose={handleCloseModal}
            initialData={editingBlock?.type === "contact" ? editingBlock.content : null}
          />

          <AddEventModal
            isOpen={showAddEventModal}
            onSave={handleSaveEvent}
            onClose={handleCloseModal}
            initialData={editingBlock?.type === "event" ? editingBlock.content : null}
          />

          <DeleteBlockModal
            isOpen={showDeleteModal}
            onOk={() => deleteBlock(blockToDelete?.id)}
            onCancel={() => {
              setShowDeleteModal(false)
              setBlockToDelete(null)
            }}
          />

          <div className="builder-content">
            <div className="button-grid">
              {moduleTypes.map((type) => (
                <Button key={type} className={`add-block-button ${type}`} onClick={() => openModal(type)}>
                  <Plus className="plus-icon" />
                  New {type.charAt(0).toUpperCase() + type.slice(1)}
                </Button>
              ))}
            </div>
            <Separator />
            <ScrollArea className="blocks-list">
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="blocks">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef} className="blocks-container">
                      {displayBlocksReverse.map((block, index) => (
                        <Draggable key={block.id} draggableId={block.id} index={index}>
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              className="draggable-block"
                            >
                              <div className="block-actions">
                                <div {...provided.dragHandleProps} className="action-button drag-handle">
                                  <GripVertical size={20} />
                                </div>
                                <button className="action-button edit" onClick={() => handleEdit(block)}>
                                  <Edit2 size={20} />
                                </button>
                                <button className="action-button delete" onClick={() => handleDelete(block)}>
                                  <Trash2 size={20} />
                                </button>
                              </div>
                              <PreviewBlock isDesktop block={block} />
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </ScrollArea>
          </div>
        </div>

        <PreviewPane
          blocks={blocks}
          user={{ ...user, firstName: name, profileImage }}
          bio={bio}
          onEditBio={() => setShowBioModal(true)}
          socialLinks={socialLinks}
          onAddSocial={() => setShowAddSocialModal(true)}
          theme={currentTheme}
          customerUrl={customerUrl}
          onPublish={handlePublish}
        />

        <BioModal
          isOpen={showBioModal}
          onClose={() => setShowBioModal(false)}
          onSave={handleSaveProfile}
          initialData={{
            bio: bio,
            name: name,
            profileImage,
          }}
        />

        <AddSocialModal
          isOpen={showAddSocialModal}
          onClose={() => setShowAddSocialModal(false)}
          onSave={handleSaveSocialLink}
          onEdit={handleEditSocialLink}
          onDelete={handleDeleteSocialLink}
          existingPlatforms={socialLinks}
        />

        {/* Publish Success Modal */}
        {showPublishSuccess && (
          <div className="publish-success-modal">
            <div className="publish-success-content">
              <div className="publish-success-icon">
                <CheckCircle size={30} />
              </div>
              <h3 className="publish-success-title">Link Page Published!</h3>
              <p className="publish-success-message">
                Your link page has been successfully published and is now available in the Promo Manager.
              </p>

              {/* Promo Card Preview */}
              <div className="promo-card-preview">
                <div className="promo-type">Promo Type: Link Page</div>
                <div className="promo-status">
                  <span className="status-label">Active</span>
                  <div className="status-toggle"></div>
                </div>
                <div className="promo-stats">
                  <div className="stat-item">
                    <Eye className="stat-icon" size={20} />
                    <span className="stat-label">Views</span>
                  </div>
                  <div className="stat-item">
                    <MousePointer className="stat-icon" size={20} />
                    <span className="stat-label">Clicks</span>
                  </div>
                  <div className="stat-item">
                    <Users className="stat-icon" size={20} />
                    <span className="stat-label">Fans</span>
                  </div>
                </div>
                <div className="promo-date">
                  Created on{" "}
                  {new Date().toLocaleDateString("en-US", { month: "short", day: "numeric", year: "numeric" })}
                </div>
              </div>

              <button className="publish-success-button" onClick={handleGoToPromoManager}>
                Go to Promo Manager
              </button>
            </div>
          </div>
        )}
      </div>
      <AnalyticsDialog isOpen={isAnalyticsOpen} onClose={() => setIsAnalyticsOpen(false)} />
    </div>
  )
}

const PreviewPane = ({ blocks, user, bio, onEditBio, socialLinks, onAddSocial, theme, customerUrl, onPublish }) => {
  const mobileContactFormRef = useRef(null)

  const getInitials = (name) => {
    return name ? name.charAt(0).toUpperCase() : "?"
  }

  const showProfileIcons = () => {
    const icons = Object.entries(socialLinks).map(([platform, data]) => (
      <SocialIcon key={platform} platform={platform} username={data.username} />
    ))

    return (
      <div className="social-icons-wrapper">
        {icons}
        <button onClick={onAddSocial} className="add-social-button">
          <Plus className="w-4 h-4" />
        </button>
      </div>
    )
  }

  const displayBlocksReverse = [...blocks].reverse()

  return (
    <div className="preview-pane">
      <div className="preview-header">
        <h2 className="title2">Preview</h2>
        {blocks.length && (
          <button className="link-publish-button" onClick={onPublish}>
            Publish
          </button>
        )}
      </div>
      <div className="phone-mockup">
        <div className="phone-notch"></div>

        <div ref={mobileContactFormRef} className="phone-content" data-theme={theme}>
          {/* Profile Section */}
          <div className="profile-section">
            {/* Avatar */}
            <div className="avatar-container">
              {user.profileImage ? (
                <img src={user.profileImage} alt="Avatar" className="avatar-image" />
              ) : (
                <div className="avatar-fallback">{getInitials(user.firstName)}</div>
              )}
              <button className="edit-bio-button" onClick={onEditBio}>
                <Pencil size={16} />
              </button>
            </div>
            {/* Username */}
            <h3 className="username">{user.firstName}</h3>
            {bio && <p className="user-bio">{bio}</p>}
            {showProfileIcons()}
          </div>

          {/* Customer URL display if provided */}
          {customerUrl && (
            <div className="customer-url-display">
              <div className="customer-url-text">
                <span>Customer URL: {customerUrl}</span>
              </div>
            </div>
          )}

          {/* Preview Blocks */}
          {displayBlocksReverse.map((block) => (
            <PreviewBlock mobileContactFormRef={mobileContactFormRef} key={block.id} block={block} />
          ))}
        </div>
      </div>
    </div>
  )
}

const BioModal = ({ isOpen, onClose, onSave, initialData }) => {
  const [formData, setFormData] = useState({
    name: initialData?.name || "",
    bio: initialData?.bio || "",
    profileImage: initialData?.profileImage || "",
  })
  const [dragActive, setDragActive] = useState(false)
  const dialogRef = useRef(null)
  const fileInputRef = useRef(null)
  const maxBioLength = 80
  const maxNameLength = 30

  useEffect(() => {
    if (isOpen) {
      dialogRef.current?.showModal()
      document.body.style.overflow = "hidden"
      setFormData({
        name: initialData?.name || "",
        bio: initialData?.bio || "",
        profileImage: initialData?.profileImage || "",
      })
    } else {
      dialogRef.current?.close()
      document.body.style.overflow = "unset"
    }
  }, [isOpen, initialData])

  const handleChange = (field, value) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }))
  }

  const handleDrag = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true)
    } else if (e.type === "dragleave") {
      setDragActive(false)
    }
  }

  const handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)

    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFile(e.dataTransfer.files[0])
    }
  }

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      handleFile(e.target.files[0])
    }
  }

  const handleFile = (file) => {
    if (file.type.startsWith("image/")) {
      const reader = new FileReader()
      reader.onload = (e) => {
        handleChange("profileImage", e.target?.result)
      }
      reader.readAsDataURL(file)
    }
  }

  const handleSave = () => {
    if (formData.name.trim()) {
      onSave(formData)
    }
  }

  const getInitials = (name) => {
    return name ? name.charAt(0).toUpperCase() : "?"
  }

  return (
    <dialog ref={dialogRef} className="bio-modal">
      <div className="bio-modal-header">
        <h2 className="bio-modal-title">Edit Profile</h2>
        <button onClick={onClose} className="bio-modal-close-button">
          <X className="bio-modal-x" />
        </button>
      </div>
      <form onSubmit={(e) => e.preventDefault()}>
        <div
          className={`profile-image-upload ${dragActive ? "drag-active" : ""}`}
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
          onClick={() => fileInputRef.current?.click()}
        >
          {formData.profileImage ? (
            <img src={formData.profileImage || "/placeholder.svg"} alt="Profile" className="profile-image-preview" />
          ) : (
            <div className="profile-image-placeholder">{getInitials(formData.name)}</div>
          )}
          <div className="profile-image-overlay">
            <Upload className="upload-icon" size={20} />
            <span>Upload Image</span>
          </div>
          <input ref={fileInputRef} type="file" onChange={handleFileChange} accept="image/*" className="hidden-input" />
        </div>

        <div className="bio-form-group">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            id="name"
            value={formData.name}
            onChange={(e) => handleChange("name", e.target.value)}
            maxLength={maxNameLength}
            placeholder="Enter your name"
            className="bio-input"
          />
          <div className="bio-character-count">
            {formData.name.length}/{maxNameLength}
          </div>
        </div>
        <div className="bio-form-group">
          <label htmlFor="bio">Bio</label>
          <textarea
            id="bio"
            value={formData.bio}
            onChange={(e) => handleChange("bio", e.target.value)}
            maxLength={maxBioLength}
            placeholder="Write something about yourself..."
            rows={4}
          />
          <div className="bio-character-count">
            {formData.bio.length}/{maxBioLength}
          </div>
        </div>
        <div className="bio-button-group">
          <button type="button" onClick={onClose} className="bio-cancel-button">
            Cancel
          </button>
          <button type="button" onClick={handleSave} className="bio-save-button" disabled={!formData.name.trim()}>
            Save
          </button>
        </div>
      </form>
    </dialog>
  )
}

const PreviewBlock = ({ isDesktop, block, mobileContactFormRef }) => {
  const [showMobileForm, setShowMobileForm] = useState(false)
  const blockRef = useRef(null)

  useEffect(() => {
    if (blockRef.current && !isDesktop) {
      const element = blockRef.current

      const handleMouseMove = (e) => {
        const rect = element.getBoundingClientRect()
        const x = e.clientX - rect.left
        const y = e.clientY - rect.top

        element.style.setProperty("--mouse-x", `${x}px`)
        element.style.setProperty("--mouse-y", `${y}px`)
      }

      element.addEventListener("mousemove", handleMouseMove)

      return () => {
        element.removeEventListener("mousemove", handleMouseMove)
      }
    }
  }, [isDesktop])

  const handleContactClick = () => {
    if (!isDesktop && block.type === "contact") {
      setShowMobileForm(true)
    }
  }

  switch (block.type) {
    case "link":
      return (
        <Card ref={blockRef} className={`preview-block ${isDesktop ? "preview-block-desktop" : ""} ${!isDesktop ? 'glow-effect' : ''}`}>
          {block.content.imageUrl && (
            <Avatar src={block.content.imageUrl} shape="square" size={64} className="block-avatar" />
          )}
          <a href={block.content.url} target="_blank" className="preview-link" rel="noreferrer">
            {block.content.title}
          </a>
          {!isDesktop && (
            <div className="glow-container">
              <div className="glow-blur"></div>
            </div>
          )}
        </Card>
      )
    case "text":
      return (
        <Card
          ref={blockRef}
          className={`preview-block ${isDesktop ? "preview-block-desktop" : ""} preview-block-text ${!isDesktop ? 'glow-effect' : ''}`}
        >
          <div className="preview-text-wrapper">
            <p className="preview-text">{block.content.content}</p>
            {!isDesktop && (
              <div className="glow-container">
                <div className="glow-blur"></div>
              </div>
            )}
          </div>
        </Card>
      )
    case "contact":
      return (
        <>
          <Card
            ref={blockRef}
            className={`preview-block ${isDesktop ? "preview-block-desktop" : ""} ${!isDesktop ? 'glow-effect' : ''}`}
            onClick={handleContactClick}
          >
            <div className={`preview-contact ${isDesktop ? "preview-contact-desktop" : ""}`}>
              {block.content.layout === "center" ? (
                <div className={`block-card image-wrapper-center ${isDesktop ? "image-wrapper-center-desktop" : ""}`}>
                  {block.content.image && <Avatar size={64} src={<img src={block.content.image} alt="avatar" />} />}
                  <div className={`${isDesktop ? "content-wrapper-center-desktop" : ""}`}>
                    <p className="text-center">{block.content.title}</p>
                    <p className="text-center">{block.content.description}</p>
                  </div>
                </div>
              ) : (
                <>
                  <div className="block-card">
                    {block.content.image && <Avatar size={64} src={<img src={block.content.image} alt="avatar" />} />}
                    <div className="content-wrapper-left">
                      <p className="text-center">{block.content.title}</p>
                      <p className="text-center">{block.content.description}</p>
                    </div>
                  </div>
                </>
              )}
            </div>
            {!isDesktop && (
              <div className="glow-container">
                <div className="glow-blur"></div>
              </div>
            )}
          </Card>
          {!isDesktop && (
            <MobileContactForm
              isOpen={showMobileForm}
              onClose={() => setShowMobileForm(false)}
              fields={block.content.fields || []}
              title={block.content.title}
              description={block.content.description}
              parentRef={mobileContactFormRef}
            />
          )}
        </>
      )
    case "event":
      return (
        <Card
          ref={blockRef}
          className={`preview-block ${isDesktop ? "preview-block-desktop" : ""} event ${!isDesktop ? 'glow-effect' : ''}`}
          onClick={() => window.open(block.content.ticketLink, "_blank")}
        >
          <span className="event-card-wrapper">
            <p className="event-card-info-secondary">{block.content.eventDate.month}</p>
            <p className="event-card-info-primary">{block.content.eventDate.day}</p>
            <p className="event-card-info-secondary">{block.content.eventDate.year}</p>
          </span>
          <div className="block-content">
            <h4 className="block-title">{block.content.venueName}</h4>
            <p className="block-description">{block.content.location}</p>
            <p className="block-description sold-out">{block.content.isSoldOut ? "Sold Out" : "Tickets"}</p>
          </div>
          {!isDesktop && (
            <div className="glow-container">
              <div className="glow-blur"></div>
            </div>
          )}
        </Card>
      )
    default:
      return null
  }
}

export default withRouter(TemplateBuilder);
